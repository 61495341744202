import React, { useState, Fragment } from 'react';
import cover from './cover.jpg';
import loop from './loop.mp4';
import './App.css';
import itunes from './icon_itunes.svg';
import youtube from './icon_youtube.svg';
import spotify from './icon_spotify.svg';
import applemusic from './icon_applemusic.svg';
import tidal from './icon_tidal.png';
import soundcloud from './icon_soundcloud.png';

function App() {
  return (
    <div className="app">
      <div className="background-container">
        <video src={loop} autoPlay loop muted />
        <div className="background-overlay"></div>
      </div>
      <Content />
    </div>
  );
}
function Info(props) {
  return (
    <Fragment>
      <img src={cover} alt="The album cover" />
      <div className="info">
        <h3>The new album</h3>
        <h2>ANOTHER PART OF ME</h2>
        <h3>Out now</h3>
        <button type="button" onClick={props.handleClick}>
          STREAM
        </button>
      </div>
    </Fragment>
  );
}

function Content() {
  const [stream, setStream] = useState(false);
  const clickHandler = () => {
    setStream(true);
  };
  return (
    <div className="content">
      <h1 className="title" onClick={() => setStream(false)}>
        PLACIDE
      </h1>
      <div className="standfirst">{stream ? <Stream /> : <Info handleClick={clickHandler} />}</div>
    </div>
  );
}

const Stream = () => {
  return (
    <div className="streamers">
      <ul>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Soundcloud
            <div className="graphic tidal">
              <img src={soundcloud} alt="" />
            </div>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Spotify
            <div className="graphic">
              <img src={spotify} alt="" />
            </div>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Itunes
            <div className="graphic">
              <img src={itunes} alt="" />
            </div>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Apple Music
            <div className="graphic">
              <img src={applemusic} alt="" />
            </div>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Youtube
            <div className="graphic">
              <img src={youtube} alt="" />
            </div>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/plcde/another-part-of-me" target="_blank" rel="noopener noreferrer">
            Tidal
            <div className="graphic tidal">
              <img src={tidal} alt="" />
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default App;
